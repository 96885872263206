<template>
  <div>
    <roles-table-head class="mt-5 mb-5"></roles-table-head>
    <div>
      <v-text-field v-model="search" placeholder="Buscar usuarios" color="primary" background-color="#DDDDE4"
        prepend-inner-icon="mdi-magnify" outlined hide-details></v-text-field>
    </div>

    <roles-table-item v-for="user in userFilters" :key="user.username" :user="user" @updateRole="updateRole"></roles-table-item>

    <v-progress-linear v-if="isLoading" indeterminate />

    <p v-if="users.length === 0" class="text-center mt-2">
      No hay resultados
    </p>
  </div>
</template>

<script>
// Domain
import Roles from "../../domain/Roles";

export default {
  name: "RolesTable",
  mounted() {
    this.getAll();
  },
  components: {
    RolesTableHead: () => import("./table/RolesTableHead.vue"),
    RolesTableItem: () => import("./table/RolesTableItem.vue")
  },
  props: {
    updated: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  data: () => ({
    users: [],
    search: "",
    isLoading: true,
  }),
  methods: {
    async getAll() {
      try {
        this.users = await Roles.getAllUsers();
        console.log(this.users);
        this.isLoading = false;
      } catch (error) {
        console.warn(error);
      }
    },
    updateRole(user) {
      console.log("updateRole", user);
      this.$emit("updateRole", user);
    }
  },
  computed: {
    userFilters() {
      return this.users.filter(user => {
        return (
          user.username.toUpperCase().includes(this.search.toUpperCase())
        );
      });
    }
  },
  watch: {
    updated: async function () {
      if (this.updated) {
        this.users = [];
        await this.getAll();
        this.$emit("updatedComplete");
      }
    }
  }
};
</script>

<style lang="scss">

</style>